import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  SelectInput,
  PasswordInput,
  AutocompleteArrayInput,
  useTranslate,
  required,
  regex,
  email,
  useNotify,
  useRefresh,
  Confirm,
} from 'react-admin';
import { Grid } from '@mui/material';
import { actions } from '../../../redux/user/accounts';
import EditToolBar from '../../../components/commons/editToolBar';
import EditActions from '../../../components/user/accounts/editActions';
import canAccess from '../../../utils/canAccess';
import FUNC from '../../../utils/functions';

export default React.memo(() => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const dispatch = useDispatch();

  const { permissions } = useSelector((state: any) => state.app.appState);
  const { roles, genders } = useSelector((state: any) => state.users);

  const [version, setVersion] = React.useState(0);
  const [isConfirm, setIsConfirm] = React.useState(false);
  const [msg, setMsg] = React.useState('');
  const [clickedButton, setClickedButton] = React.useState(0);
  const [id, setId] = React.useState(0);

  const canEdit = canAccess(permissions, FUNC.EDIT_USER);
  const canDelete = canAccess(permissions, FUNC.DELETE_USER);
  const canSearch = canAccess(permissions, FUNC.SEARCH_USERS);
  const canShow = canAccess(permissions, FUNC.SHOW_USER_DETAIL);
  const canLock = canAccess(permissions, FUNC.LOCK_ACCOUNT);
  const canUnlock = canAccess(permissions, FUNC.UNLOCK_ACCOUNT);
  const canAddBlacklist = canAccess(permissions, FUNC.ADD_ACCOUNT_TO_BLACK_LIST);
  const canRemoveBlacklist = canAccess(permissions, FUNC.REMOVE_ACCOUNT_FROM_BLACK_LIST);

  const confirmPasswordValidation = (value: string, allValues: any) => {
    if (allValues.confirmPassword !== value) {
      return 'dut.msg.confirm_password_invalidate';
    }
    return undefined;
  };

  const handleDialogClose = () => setIsConfirm(false);

  const lockAccount = (record: any) => {
    setMsg(translate('dut.msg.confirm_lock_account'));
    setIsConfirm(true);
    setClickedButton(1);
    setId(record.id);
  };
  const unlockAccount = (record: any) => {
    setMsg(translate('dut.msg.confirm_unlock_account'));
    setIsConfirm(true);
    setClickedButton(2);
    setId(record.id);
  };
  const addToBlackList = (record: any) => {
    setMsg(translate('dut.msg.confirm_add_account_to_black_list'));
    setIsConfirm(true);
    setClickedButton(3);
    setId(record.id);
  };
  const removeFromBlackList = (record: any) => {
    setMsg(translate('dut.msg.confirm_remove_account_from_black_list'));
    setIsConfirm(true);
    setClickedButton(4);
    setId(record.id);
  };
  const onSuccess = () => {
    setVersion(version + 1);
    notify('dut.msg.save_data_success', {
      type: 'info',
    });
    refresh();
  };
  const payload = {
    data: { id },
    onSuccess,
  };
  const handleConfirm = () => {
    if (clickedButton === 1) {
      dispatch(actions.lockAccount(payload));
    }
    if (clickedButton === 2) {
      dispatch(actions.unlockAccount(payload));
    }
    if (clickedButton === 3) {
      dispatch(actions.addToBlackList(payload));
    }
    if (clickedButton === 4) {
      dispatch(actions.removeFromBlackList(payload));
    }
    setIsConfirm(false);
  };

  return (
    <div className="dut-form">
      <Edit
        actions={(
          <EditActions
            isShow={canShow}
            isList={canSearch}
            isLockAccount={canLock}
            isUnlockAccount={canUnlock}
            isAddToBlackList={canAddBlacklist}
            isRemoveFromBlackList={canRemoveBlacklist}
            onClickLockAccount={lockAccount}
            onClickUnLockAccount={unlockAccount}
            onClickAddToBlackList={addToBlackList}
            onClickRemoveFromBlackList={removeFromBlackList}
          />
        )}
        mutationMode="pessimistic"
      >
        <SimpleForm toolbar={<EditToolBar isSave={canEdit} isDelete={canDelete} />}>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={4} md={1} />
            <Grid item xs={8} md={5}>
              <TextInput
                source="firstName"
                validate={required()}
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={8} md={5}>
              <TextInput
                source="lastName"
                validate={required()}
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={4} md={1} />
            <Grid item xs={8} md={5}>
              <TextInput
                source="email"
                validate={[required(), email()]}
                type="email"
                fullWidth
                variant="outlined"
                helperText={false}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>
            <Grid item xs={8} md={5}>
              <TextInput
                source="phone"
                variant="outlined"
                helperText={false}
                fullWidth
                validate={[
                  regex(
                    /^[(]?[0-9]{1,3}[)]? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})? ?(w{1,10}s?d{1,6})?$/,
                    'dut.msg.phone_number_format_invalidate',
                  ),
                ]}
                inputProps={{ maxLength: 15 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={4} md={1} />
            <Grid item xs={8} md={5}>
              <TextInput
                source="username"
                validate={[
                  required(),
                  regex(/^[a-zA-Z0-9_.-]{4,50}$/, 'dut.msg.username_format_invalidate'),
                ]}
                fullWidth
                variant="outlined"
                helperText={false}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={8} md={5}>
              <AutocompleteArrayInput
                source="roleIds"
                choices={roles ?? []}
                validate={required()}
                variant="outlined"
                helperText={false}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={4} md={1} />
            <Grid item xs={8} md={5}>
              <PasswordInput
                source="password"
                fullWidth
                variant="outlined"
                helperText={false}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={8} md={5}>
              <PasswordInput
                source="confirmPassword"
                validate={[
                  confirmPasswordValidation,
                ]}
                fullWidth
                variant="outlined"
                helperText={false}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={4} md={1} />
            <Grid item xs={8} md={5}>
              <SelectInput
                source="gender"
                validate={required()}
                choices={genders ?? []}
                emptyValue={null}
                fullWidth
                variant="outlined"
                helperText={false}
                optionText={(choice) => (choice.name ? translate(`dut.${choice.name.toLowerCase()}`) : '')}
              />
            </Grid>
            <Grid item xs={8} md={5}>
              <DateInput
                source="birthday"
                variant="outlined"
                helperText={false}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={4} md={1} />
            <Grid item xs={8} md={5}>
              <TextInput
                source="taxCode"
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 100 }}
                rows={3}
              />
            </Grid>
            <Grid item xs={8} md={5}>
              <TextInput
                source="nationality"
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 255 }}
                rows={3}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={4} md={1} />
            <Grid item xs={8} md={5}>
              <TextInput
                source="ethicity"
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 255 }}
                rows={3}
              />
            </Grid>
            <Grid item xs={8} md={5}>
              <TextInput
                source="religion"
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 255 }}
                rows={3}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={4} md={1} />
            <Grid item xs={16} md={10}>
              <TextInput
                multiline
                source="note"
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 500 }}
                rows={3}
              />
            </Grid>
          </Grid>
        </SimpleForm>
      </Edit>
      <Confirm
        isOpen={isConfirm}
        title={translate('dut.confirm')}
        content={msg}
        onConfirm={() => { handleConfirm(); }}
        onClose={handleDialogClose}
      />
    </div>
  );
});
