/* eslint-disable no-param-reassign */
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TextInput,
  SelectInput,
  PasswordInput,
  required,
  regex,
  ImageInput,
  ImageField,
  AutocompleteInput,
  FormDataConsumer,
  useTranslate,
  useRecordContext,
  AutocompleteArrayInput,
  DateInput,
} from 'react-admin';
import { Grid } from '@mui/material';
import { actions } from '../../../redux/user/students';
import { STUDENT_STATUS } from '../../../utils/constant';
import repositories from '../../../repositories/user/students';

export default React.memo((props: any) => {
  const dispatch = useDispatch();
  const translate = useTranslate();

  const {
    activityClasses,
    faculties,
    courses,
    majors,
    positionsInClass,
    studentTitles,
    roles,
    studentEmailDomain,
    studentStatus,
  } = useSelector((state: any) => state.students);

  const [avatarChange, setAvatarChange] = React.useState(false);
  const [isInit, setIsInit] = React.useState(true);

  const record = useRecordContext();
  const avatar = record?.information?.avatar;
  if (avatar && isInit) {
    if (record?.information?.avatar) {
      const getImage = async () => {
        const response = await repositories.getAvatarImage(record.information.avatar);
        if (response.status === 200) {
          setIsInit(false);
        }
        const blob = new Blob([response.data]);
        return window.URL.createObjectURL(blob);
      };
      getImage().then((url: string) => {
        record.information.avatar = url;
      });
    }
  }

  const confirmPasswordValidation = (value: string, allValues: any) => {
    if (allValues.confirmPassword !== value) {
      return 'dut.msg.confirm_password_invalidate';
    }
    return undefined;
  };
  const removeReadOnly = (event:any) => {
    const input = event.target;
    input.readOnly = false;
  };

  const getData = (facultyId: any, courseId: any) => {
    if (facultyId === '') {
      facultyId = 0;
    }
    if (courseId === '') {
      courseId = 0;
    }
    dispatch(actions.getInitData({ facultyId, courseId }));
  };

  const isDisplayPendingDate = (status:any) => (
    status === STUDENT_STATUS.SUSPENSION || status === STUDENT_STATUS.PENDING);

  const FetchData = () => {
    useEffect(() => {
      getData(record?.faculty, record?.course);
    }, []);
  };

  const { isEdit, canUpdateStudentRole } = props;

  if (isEdit) {
    FetchData();
  }

  return (
    <FormDataConsumer>
      {({ formData }) => (
        <Grid
          container
          spacing={2}
          rowSpacing={0.5}
          marginLeft={0.5}
          marginRight={2}
          sx={
            {
              alignItems: 'center',
              flexWrap: 'nowrap',
            }
          }
        >
          <Grid container spacing={2} rowSpacing={0.5} sx={{ marginRight: 2, width: '20%' }}>
            <Grid item xs={12} md={12}>
              {avatarChange
                ? (
                  <ImageInput
                    accept="image/*"
                    source="information.avatar"
                    label={false}
                    placeholder={translate('resources.user/students.fields.avatar')}
                    fullWidth
                    onChange={() => { setAvatarChange(true); }}
                    onRemove={() => { setAvatarChange(false); }}
                    className="newAvatar"
                  >
                    <ImageField
                      source="src"
                      title="title"
                      sx={{
                        '& img': {
                          maxWidth: 300,
                          objectFit: 'contain',
                        },
                      }}
                    />
                  </ImageInput>
                )
                : (
                  <>
                    <ImageField
                      source="information.avatar"
                      title="Avatar"
                      sx={{ '& img': { maxWidth: 300, objectFit: 'contain' } }}
                    />
                    <ImageInput
                      accept="image/*"
                      source="information.avatar"
                      label={false}
                      placeholder={translate('resources.user/students.fields.avatar')}
                      fullWidth
                      onChange={() => { setAvatarChange(true); }}
                      onRemove={() => { setAvatarChange(false); }}
                    />
                  </>
                )}
            </Grid>
          </Grid>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={4} md={4}>
              <TextInput
                source="username"
                validate={required()}
                variant="outlined"
                helperText={false}
                fullWidth
                disabled={isEdit}
                inputProps={{
                  maxLength: 50,
                  readOnly: true,
                }}
                onFocus={removeReadOnly}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <PasswordInput
                source="password"
                fullWidth
                variant="outlined"
                helperText={false}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <PasswordInput
                source="confirmPassword"
                validate={[
                  confirmPasswordValidation,
                ]}
                fullWidth
                variant="outlined"
                helperText={false}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>

            <Grid item xs={4} md={4}>
              <TextInput
                source="information.lastName"
                validate={required()}
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <TextInput
                source="information.firstName"
                validate={required()}
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <TextInput
                source="information.otherName"
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 100 }}
              />
            </Grid>

            <Grid item xs={4} md={4}>
              <AutocompleteInput
                source="faculty"
                choices={faculties ?? []}
                variant="outlined"
                helperText={false}
                fullWidth
                onChange={(choice) => { getData(choice, formData.course); }}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <AutocompleteInput
                source="major"
                choices={majors ?? []}
                variant="outlined"
                helperText={false}
                emptyValue={null}
                fullWidth
              />
            </Grid>
            {canUpdateStudentRole
              ? (
                <Grid item xs={4} md={4}>
                  <AutocompleteArrayInput
                    source="roleIds"
                    choices={roles ?? []}
                    variant="outlined"
                    helperText={false}
                    fullWidth
                  />
                </Grid>
              )
              : (<Grid item xs={4} md={4} />)}

            <Grid item xs={4} md={4}>
              <AutocompleteInput
                source="course"
                choices={courses ?? []}
                variant="outlined"
                helperText={false}
                fullWidth
                onChange={(choice) => { getData(formData.faculty, choice); }}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <AutocompleteInput
                source="activityClass"
                choices={activityClasses ?? []}
                variant="outlined"
                helperText={false}
                fullWidth
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <SelectInput
                source="currentPosition"
                choices={positionsInClass ?? []}
                fullWidth
                variant="outlined"
                helperText={false}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <SelectInput
                source="studentTitle"
                choices={studentTitles ?? []}
                fullWidth
                defaultValue="SV"
                variant="outlined"
                helperText={false}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <TextInput
                source="username"
                id="email"
                label="resources.user/students.fields.email"
                format={(v:any) => `${v}${studentEmailDomain}`}
                disabled
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 255 }}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <TextInput
                source="phone"
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 20 }}
                validate={[
                  required(),
                  regex(
                    /^[(]?[0-9]{1,3}[)]? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})? ?(w{1,10}s?d{1,6})?$/,
                    'dut.msg.phone_number_format_invalidate',
                  ),
                ]}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextInput
                multiline
                source="note"
                variant="outlined"
                helperText={false}
                fullWidth
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <SelectInput
                source="status"
                choices={studentStatus ?? []}
                fullWidth
                variant="outlined"
                helperText={false}
              />
            </Grid>
            {isDisplayPendingDate(formData.status)
              ? (
                <>
                  <Grid item xs={4} md={4}>
                    <DateInput
                      source="startPendingAt"
                      variant="outlined"
                      helperText={false}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <DateInput
                      source="endPendingAt"
                      variant="outlined"
                      helperText={false}
                      fullWidth
                      required
                    />
                  </Grid>
                </>
              )
              : (<Grid item xs={4} md={4} />)}
          </Grid>
        </Grid>
      )}
    </FormDataConsumer>
  );
});
