/* eslint-disable react/jsx-fragments */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TextInput,
  SelectInput,
  PasswordInput,
  required,
  regex,
  ImageInput,
  ImageField,
  FormDataConsumer,
  email,
  useTranslate,
  useRecordContext,
  ArrayInput,
  SimpleFormIterator,
  AutocompleteArrayInput,
} from 'react-admin';
import { Grid, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import store from '../../../redux/store';
import { actions as appActions } from '../../../redux/app';
import { actions } from '../../../redux/user/teachers';
import majorRepository from '../../../repositories/master/majors';
import repositories from '../../../repositories/user/students';

export default React.memo((props: any) => {
  const dispatch = useDispatch();
  const translate = useTranslate();

  const {
    faculties,
    typeOfTeachers,
    departments,
    positionsInFaculty,
    positionsInDepartment,
    roles,
    positionsInMajor,
  } = useSelector((state: any) => state.teachers);
  const confirmPasswordValidation = (value: string, allValues: any) => {
    if (allValues.password !== value) {
      return 'dut.msg.confirm_password_invalidate';
    }
    return undefined;
  };
  const removeReadOnly = (event:any) => {
    const input = event.target;
    input.readOnly = false;
  };

  const [avatarChange, setAvatarChange] = React.useState(false);
  const [isInit, setIsInit] = React.useState(true);
  const record = useRecordContext();
  const avatar = record?.information?.avatar;
  useEffect(() => {
    if (avatar && isInit) {
      store.dispatch(appActions.loading(true));
      if (record?.information?.avatar) {
        const getImage = async () => {
          const response = await repositories.getAvatarImage(record.information.avatar);
          if (response.status === 200) {
            setIsInit(false);
          }
          const blob = new Blob([response.data]);
          return window.URL.createObjectURL(blob);
        };
        getImage().then((url: string) => {
          record.information.avatar = url;
        });
      }
    }
    store.dispatch(appActions.loading(false));
  }, [avatar]);

  const getData = (facultyId: any, courseId: any) => {
    if (facultyId === '') {
      facultyId = 0;
    }
    if (courseId === '') {
      courseId = 0;
    }
    dispatch(actions.getInitData({ facultyId, courseId }));
  };

  const FetchData = () => {
    useEffect(() => {
      getData(record?.faculty, record?.course);
    }, []);
  };

  const { isEdit, canUpdateTeacherRole } = props;

  if (isEdit) {
    FetchData();
  }

  const getMajor = (scopedFormData: any) => {
    if (!scopedFormData) {
      return [];
    }
    if (scopedFormData.majorOptions) {
      return scopedFormData.majorOptions;
    }

    return [];
  };

  const onChangeHandle = async (event: any, scopedFormData:any) => {
    scopedFormData.major = null;
    if (!event.target.value) {
      scopedFormData.majorOptions = [];
      return;
    }
    const majors = await majorRepository.get(event.target.value);
    scopedFormData.majorOptions = majors.data.list;
  };

  return (
    <FormDataConsumer>
      {() => (
        <Grid
          container
          spacing={2}
          rowSpacing={0.5}
          marginLeft={0.5}
          marginRight={2}
          sx={
            {
              alignItems: 'center',
              flexWrap: 'nowrap',
            }
          }
        >
          <Grid
            container
            spacing={2}
            rowSpacing={0.5}
            sx={{
              marginRight: 3,
              width: '20%',
              marginTop: '20%',
              alignSelf: 'start',
            }}
          >
            <Grid item xs={12} md={12}>
              {avatarChange
                ? (
                  <ImageInput
                    accept="image/*"
                    source="information.avatar"
                    label={false}
                    placeholder={translate('resources.user/teachers.fields.avatar')}
                    fullWidth
                    onChange={() => { setAvatarChange(true); }}
                    onRemove={() => { setAvatarChange(false); }}
                    className="newAvatar"
                  >
                    <ImageField
                      source="src"
                      title="title"
                      sx={{
                        '& img': {
                          maxWidth: 300,
                          objectFit: 'contain',
                        },
                      }}
                    />
                  </ImageInput>
                )
                : (
                  <>
                    <ImageField
                      source="information.avatar"
                      title="Avatar"
                      sx={{ '& img': { maxWidth: 300, objectFit: 'contain' } }}
                    />
                    <ImageInput
                      accept="image/*"
                      source="information.avatar"
                      label={false}
                      placeholder={translate('resources.user/teachers.fields.avatar')}
                      fullWidth
                      onChange={() => { setAvatarChange(true); }}
                      onRemove={() => { setAvatarChange(false); }}
                    />
                  </>
                )}
            </Grid>
          </Grid>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid container spacing={2} rowSpacing={0.5}>
              <Grid item xs={4} md={4}>
                <TextInput
                  source="username"
                  validate={required()}
                  variant="outlined"
                  helperText={false}
                  fullWidth
                  disabled={isEdit}
                  inputProps={{
                    maxLength: 50,
                    readOnly: true,
                  }}
                  onFocus={removeReadOnly}
                />
              </Grid>
              <Grid item xs={4} md={4}>
                <PasswordInput
                  source="password"
                  fullWidth
                  variant="outlined"
                  helperText={false}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xs={4} md={4}>
                <PasswordInput
                  source="confirmPassword"
                  validate={[
                    confirmPasswordValidation,
                  ]}
                  fullWidth
                  variant="outlined"
                  helperText={false}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>

              <Grid item xs={4} md={4}>
                <TextInput
                  source="information.lastName"
                  validate={required()}
                  variant="outlined"
                  helperText={false}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={4} md={4}>
                <TextInput
                  source="information.firstName"
                  validate={required()}
                  variant="outlined"
                  helperText={false}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={4} md={4}>
                <TextInput
                  source="information.otherName"
                  variant="outlined"
                  helperText={false}
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} rowSpacing={0.5}>
              <Grid item xs={12} md={12}>
                <Grid item xs={8} md={8} mt={3} mx={1}>
                  <Typography variant="h6">
                    {translate('resources.user/teachers.fields.teachingFaculties')}
                  </Typography>
                </Grid>
                <ArrayInput label={false} source="faculties">
                  <SimpleFormIterator
                    addButton={(
                      <Button
                        variant="contained"
                        size="medium"
                        sx={{ marginLeft: 3, textTransform: 'none', width: 180 }}
                        startIcon={<AddCircleOutlineIcon />}
                      >
                        <Typography variant="body1">
                          {translate('dut.addFaculty')}
                        </Typography>
                      </Button>
                    )}
                    disableReordering
                    inline
                    removeButton={(
                      <Tooltip title={translate('dut.removeFaculty')} arrow placement="top">
                        <DeleteIcon
                          color="error"
                          className="pointerClass"
                          fontSize="medium"
                          sx={{
                            marginLeft: 2,
                            marginTop: 1,
                          }}
                        />
                      </Tooltip>
                    )}
                    sx={{
                      marginTop: 0,
                    }}
                  >
                    <FormDataConsumer>
                      {({ getSource, scopedFormData }) => (
                        <Grid
                          container
                          spacing={2}
                          rowSpacing={0.5}
                        >
                          <Grid item xs={5} md={6}>
                            <SelectInput
                              label={translate('resources.user/teachers.fields.faculty')}
                              source={getSource?.('faculty')}
                              choices={faculties ?? []}
                              variant="outlined"
                              helperText={false}
                              fullWidth
                              onChange={async (e) => onChangeHandle(e, scopedFormData)}
                            />
                          </Grid>
                          <Grid item xs={5} md={6}>
                            <SelectInput
                              label={translate('resources.user/teachers.fields.majors.name')}
                              source={getSource?.('major')}
                              choices={getMajor(scopedFormData)}
                              variant="outlined"
                              helperText={false}
                              defaultValue={null}
                              fullWidth
                              emptyValue={null}
                            />
                          </Grid>
                          <Grid item xs={5} md={6}>
                            <Grid item xs={4} md={4} mx={1}>
                              <Typography variant="body1">
                                {translate('resources.user/teachers.fields.faculties.positions.label')}
                              </Typography>
                            </Grid>
                            <ArrayInput
                              label=""
                              source={getSource ? getSource('facultyPositions') : 'facultyPostitions'}
                            >
                              <SimpleFormIterator
                                addButton={(
                                  <Button size="small" sx={{ textTransform: 'none' }} startIcon={<AddCircleOutlineIcon />}>
                                    <Typography variant="body1">
                                      {translate('dut.addPosition')}
                                    </Typography>
                                  </Button>
                                )}
                                removeButton={(
                                  <Tooltip title={translate('dut.removePositionInFaculty')} arrow placement="top">
                                    <DeleteIcon
                                      color="error"
                                      className="pointerClass"
                                      fontSize="medium"
                                      sx={{
                                        marginLeft: 2,
                                        marginTop: 1,
                                      }}
                                    />
                                  </Tooltip>
                                )}
                                disableReordering
                              >
                                <SelectInput
                                  label={translate('resources.user/teachers.fields.faculties.positions.name')}
                                  source={isEdit ? 'id' : ''}
                                  choices={positionsInFaculty ?? []}
                                  variant="outlined"
                                  helperText={false}
                                  fullWidth
                                />
                              </SimpleFormIterator>
                            </ArrayInput>
                          </Grid>
                          <Grid item xs={5} md={6}>
                            <Grid item xs={5} md={6} mx={1}>
                              <Typography variant="body1">
                                {translate('resources.user/teachers.fields.majors.positions.label')}
                              </Typography>
                            </Grid>
                            <ArrayInput
                              label=""
                              source={getSource ? getSource('majorPositions') : 'majorPositions'}
                            >
                              <SimpleFormIterator
                                addButton={(
                                  <Button size="small" sx={{ textTransform: 'none' }} startIcon={<AddCircleOutlineIcon />}>
                                    <Typography variant="body1">
                                      {translate('dut.addPosition')}
                                    </Typography>
                                  </Button>
                                )}
                                removeButton={(
                                  <Tooltip title={translate('dut.removePositionMajor')} arrow placement="top">
                                    <DeleteIcon
                                      color="error"
                                      className="pointerClass"
                                      fontSize="medium"
                                      sx={{
                                        marginLeft: 2,
                                        marginTop: 1,
                                      }}
                                    />
                                  </Tooltip>
                                )}
                                disableReordering
                              >
                                <SelectInput
                                  label={translate('resources.user/teachers.fields.majors.positions.name')}
                                  source={isEdit ? 'id' : ''}
                                  choices={positionsInMajor ?? []}
                                  variant="outlined"
                                  helperText={false}
                                  fullWidth
                                />
                              </SimpleFormIterator>
                            </ArrayInput>
                          </Grid>
                        </Grid>
                      )}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
            </Grid>
            <Grid container spacing={2} rowSpacing={0.5}>
              <Grid item xs={12} md={12} mt={3} mx={1} className="borderTop">
                <Typography variant="h6">
                  {translate('resources.user/teachers.fields.listDepartments')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <ArrayInput
                  label={false}
                  source="departments"
                >
                  <SimpleFormIterator
                    inline
                    removeButton={(
                      <Tooltip title={translate('dut.removeDepartment')} arrow placement="top">
                        <DeleteIcon
                          color="error"
                          className="pointerClass"
                          fontSize="medium"
                          sx={{
                            marginTop: 1,
                          }}
                        />
                      </Tooltip>
                    )}
                    disableReordering
                    addButton={(
                      <Button
                        variant="contained"
                        size="medium"
                        sx={{ marginLeft: 3, textTransform: 'none', width: 180 }}
                        startIcon={<AddCircleOutlineIcon />}
                      >
                        <Typography variant="body1">
                          {translate('dut.addDepartment')}
                        </Typography>
                      </Button>
                    )}
                  >
                    <SelectInput
                      source="department"
                      choices={departments ?? []}
                      variant="outlined"
                      helperText={false}
                      fullWidth
                    />
                    {/* <Typography variant="body1">
                      {translate('resources.user/teachers.fields.departments.positions.label')}
                    </Typography> */}
                    <ArrayInput
                      label=""
                      source="positions"
                    >
                      <SimpleFormIterator
                        disableReordering
                        addButton={(
                          <Button size="small" sx={{ textTransform: 'none' }} startIcon={<AddCircleOutlineIcon />}>
                            <Typography variant="body1">
                              {translate('dut.addPosition')}
                            </Typography>
                          </Button>
                        )}
                        removeButton={(
                          <Tooltip title={translate('dut.removePositionDepartment')} arrow placement="top">
                            <DeleteIcon
                              color="error"
                              className="pointerClass"
                              fontSize="medium"
                              sx={{
                                marginLeft: 2,
                                marginRight: 2,
                                marginTop: 1,
                              }}
                            />
                          </Tooltip>
                        )}
                      >
                        <SelectInput
                          label={translate('resources.user/teachers.fields.departments.positions.name')}
                          source={isEdit ? 'id' : ''}
                          choices={positionsInDepartment ?? []}
                          variant="outlined"
                          helperText={false}
                          fullWidth
                        />
                      </SimpleFormIterator>
                    </ArrayInput>
                  </SimpleFormIterator>
                </ArrayInput>

              </Grid>
              <Grid sx={{ paddingLeft: '16px' }} container spacing={2} rowSpacing={0.5} mt={3}>
                <Grid item xs={4} md={4}>
                  <SelectInput
                    source="type"
                    choices={typeOfTeachers ?? []}
                    fullWidth
                    variant="outlined"
                    helperText={false}
                  />
                </Grid>
                <Grid item xs={4} md={4}>
                  <TextInput
                    source="email"
                    validate={[required(), email()]}
                    variant="outlined"
                    helperText={false}
                    fullWidth
                    inputProps={{ maxLength: 255 }}
                  />
                </Grid>
                <Grid item xs={4} md={4}>
                  <TextInput
                    source="phone"
                    variant="outlined"
                    helperText={false}
                    fullWidth
                    inputProps={{ maxLength: 20 }}
                    validate={[
                      required(),
                      regex(
                        /^[(]?[0-9]{1,3}[)]? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})? ?(w{1,10}s?d{1,6})?$/,
                        'dut.msg.phone_number_format_invalidate',
                      ),
                    ]}
                  />
                </Grid>
                {canUpdateTeacherRole
                  ? (
                    <Grid item xs={4} md={4}>
                      <AutocompleteArrayInput
                        source="roleIds"
                        choices={roles ?? []}
                        variant="outlined"
                        helperText={false}
                        fullWidth
                      />
                    </Grid>
                  )
                  : (<Grid item xs={4} md={4} />)}
              </Grid>
              <Grid item xs={12} md={12}>
                <TextInput
                  multiline
                  source="note"
                  variant="outlined"
                  helperText={false}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </FormDataConsumer>
  );
});
